import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";

import BannerSection from "Containers/Banner";
import ServiceSection from "Containers/Service";
import Navbar from "Containers/Navbar";

/* import PricingSection from "containers/SaasClassic/Pricing";
import PartnerSection from "containers/SaasClassic/Partner";
import TrialSection from "containers/SaasClassic/Trial";
import FeatureSection from "containers/SaasClassic/Feature";
import UpdateScreen from "containers/SaasClassic/UpdateScreen";
import TestimonialSection from "containers/SaasClassic/Testimonial";
import Newsletter from "containers/SaasClassic/Newsletter";
import Footer from "containers/SaasClassic/Footer"; */
import "@redq/reuse-modal/es/index.css";
import Seo from "components/seo";
import { graphql, StaticQuery } from "gatsby";
import ProductSlide from "Containers/ProductSlide";
import Testimonial from "Containers/Testimonial";
import FeatureSlider from "Containers/FeatureSlider";
import Footer from "Containers/Footer";
import { proceedPayment, paymePromise } from "../common/components/payment";
import { openModal, closeModal } from "@redq/reuse-modal";
import Button from "common/components/Button";
import CheckCoupon from "../common/components/CheckCoupon";

const SaasClassic = ({ location }) => {
  const [Tour, setTour] = React.useState();
  const [reviews, setReviews] = React.useState();
  const [profileVideo, setProfileVideo] = React.useState(null);
  const [profilePic, setProfilePic] = React.useState(null);
  const [paymeURL, setPaymeURL] = React.useState(null);
  const [source, setSource] = React.useState("Dguide");

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);

    let source_id = params.get("source");
    if (!source_id) {
      source_id = "Dguide";
    }

    setSource(source_id);

    async function getParams() {
      if (Tour && Tour.all_media) {
        let pVideo = Tour.all_media.filter((media) =>
          media.type.includes("video")
        )[0];
        pVideo.url = "https://www.youtube.com/embed/t_6fyHFoqEU";
        setProfileVideo(pVideo);
        const pPic = Tour.all_media.filter((media) =>
          media.type.includes("image")
        );
        setProfilePic(pPic);
        try {
          await proceedPayment(Tour, setPaymeURL, source);
        } catch (error) {
          console.log("ERROR", error);
        }
      }
    }

    getParams();
  }, [Tour]);

  /*ADD FOR COUPON START*/
  const [couponText, setCouponText] = React.useState(null);

  const setPrice = async (newPrice) => {
    Tour.price = newPrice;
    //let new_url = await proceedPayment(Tour, setPaymeURL, source)
    paymePromise(Tour, source).then((urlRes) => {
      setPaymeURL(urlRes);
      closeModal();
    });
  };

  const ModalCoupon = () => {
    return (
      <div style={{ width: "100%", height: "750px" }}>
        <CheckCoupon
          couponPlaceHolder="יש לך קופון? פה המקום להכניס אותו"
          setPrice={setPrice}
          price={Tour.price}
          setCouponText={setCouponText}
          couponText={couponText}
          t_id={Tour.id}
          source_id={source}
        />
      </div>
    );
  };

  const handleCouponModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        width: "100%",
        height: "100%",
      },
      component: ModalCoupon,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  /*ADD FOR COUPON END*/

  const ModalPayment = (paymeURL) => {
    return (
      <div
        style={{ width: "100%", height: "100%", backgroundColor: "#ffffff" }}
      >
        <iframe
          title="paymeWin"
          id="paymeWin"
          src={paymeURL.paymeURL}
          style={{
            marginTop: "10px",
            width: "100%",
            height: "100%",
            position: "absolute",
            overflowY: "scroll",
          }}
          allowFullScreen
        />
      </div>
    );
  };

  const CloseModalButton = () => (
    <Button
      className="modalCloseBtn"
      variant="fab"
      onClick={() => closeModal()}
      icon={<i className="flaticon-plus-symbol" />}
    />
  );

  const handlePaymentModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        width: "100%",
        height: "100%",
      },
      component: ModalPayment,
      componentProps: { paymeURL: paymeURL },
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  return (
    <StaticQuery
      query={graphql`
        query acoNoritQuery {
          allTour(filter: { id: { eq: "8Vs1GNvyr56RH3jsBq1n" } }) {
            nodes {
              accessible
              all_media {
                name
                order
                poster_url
                profile
                type
                url
                poster_name
              }
              audience
              description
              distance
              duration
              equipment
              hours_range {
                max
                min
              }
              id
              kosher
              location
              language
              main_sentense
              price
              rating {
                total
                votes
              }
              title
              tour_guide {
                email
                full_name
                id
                profile_image
              }
              type
              hostedBy
            }
          }
          allReviews {
            nodes {
              content
              id
              name
              time
              rating
              imgurl
              lang
            }
          }
        }
      `}
      render={(data) => {
        setReviews(data.allReviews.nodes);
        setTour(data.allTour.nodes[0]);

        if (!Tour || !reviews) return null;

        /* const pVideo = Tour.all_media.filter((media) =>
          media.type.includes("video")
        )[0];
        setProfileVideo(pVideo);
        console.log("profileVideo", profileVideo); */
        //proceedPayment();

        return (
          <ThemeProvider theme={saasClassicTheme}>
            <Fragment>
              <Seo
                title={`Dguide | ${Tour.title}`}
                description={Tour.description}
                lang={Tour.language}
                meta={[
                  "סיורים",
                  "סיורים בתל אביב",
                  "טיול בתל אביב",
                  "טיול",
                  "טיול למשפחה",
                  "מקומות לטייל בשבת",
                  "איפה אפשר לטייל",
                  "המלצות לטיולים",
                  "טיול יום",
                  "סיור יום",
                  "אטרקציות בתל אביב",
                  "סיור עצמאי",
                  "מדריך טיולים",
                  "יום כיף",
                  "מה לעשות בתל אביב",
                  "סיורים מודרכים בתל אביב",
                  `סיורים ${Tour.location}`,
                  Tour.main_sentense,
                  ...Tour.type,
                ]}
                keywords={[
                  "סיור מודרך",
                  "סיור עצמאי",
                  `מה אפשר לעשות ב ${Tour.location}`,
                ]}
              />
              <Modal />
              <ResetCSS />
              <GlobalStyle />

              <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                  {couponText && (
                    <div
                      style={{
                        textAlign: "center",
                        margin: "3px",
                        fontWeight: "550",
                      }}
                    >
                      {couponText}
                    </div>
                  )}
                  <DrawerProvider>
                    <Navbar
                      handlePaymentModal={handlePaymentModal}
                      handleCouponModal={couponText ? null : handleCouponModal}
                    />
                  </DrawerProvider>
                </Sticky>
                {profileVideo && (
                  <BannerSection
                    guideName={Tour.hostedBy}
                    tourTitle={Tour.title}
                    tourDesc={Tour.description}
                    tourTrail={profileVideo}
                    handlePaymentModal={handlePaymentModal}
                  />
                )}
                <ServiceSection
                  locTour={Tour.location}
                  distTour={Tour.distance}
                  priceTour={Tour.price}
                  durTour={Tour.duration}
                  hoursRange={Tour.hours_range}
                />
                {profilePic && <ProductSlide allPics={profilePic} />}
                <Testimonial allReviews={reviews} />
                <FeatureSlider />
                <Footer />
                {/*<UpdateScreen />
          <FeatureSection />
          <PartnerSection />
          <PricingSection />
          <TestimonialSection />
          <TrialSection />
          <Newsletter />
           */}
              </ContentWrapper>
            </Fragment>
          </ThemeProvider>
        );
      }}
    />
  );
};
export default SaasClassic;
